import { useEffect, useState } from "react";
import ProjectCard from "./components/project-card";
import Modal from "./components/modal";
import { contentfulGraphqlEndpoint, xyz } from "./utils/contentful";

function App() {
  const [activeProject, setActiveProject] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [projects, setProjects] = useState(null);

  const activateProject = (project) => {
    setActiveProject(project);
    toogleModal();
  };

  const toogleModal = () => {
    setModalActive(!modalActive);
  };

  const query = `
    query GetAllProjects {
    projectCollection {
      items {
        sys {
          id
        }
        title
        tecnologiesCollection(limit: 8){
          items {
              title
              icon {
                url
              }
          }
        }
        galleryCollection(limit: 1) {
          items {
            url
          }
        }
      }
    }
  }`;
  useEffect(() => {
    fetch(contentfulGraphqlEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${xyz}`,
      },
      body: JSON.stringify({ query }),
    })
      .then((response) => response.json())
      .then((data) => {
        setProjects(data.data.projectCollection.items);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [query]);

  return (
    <>
      {projects &&
        projects.map((element) => (
          <ProjectCard
            element={element}
            key={element.sys.id}
            handleClick={activateProject}
          />
        ))}
      {modalActive && (
        <Modal elementId={activeProject} handleClick={toogleModal} />
      )}
    </>
  );
}

export default App;
