import { u } from "./u";
import { d } from "./d";
import { t } from "./t";
import { s } from "./s";

const contentfulGraphqlEndpoint = `https://graphql.contentful.com/content/v1/spaces/${atob(
  s
)}/environments/master`;

const xyz = atob(u) + atob(d) + atob(t);

export { contentfulGraphqlEndpoint, xyz };
