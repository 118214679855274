import { useEffect, useState } from "react";
import { contentfulGraphqlEndpoint, xyz } from "../utils/contentful";

function Modal(props) {
  const [element, setElement] = useState(null);

  const query = `
    query GetProject {
      project(id: "${props.elementId}") {
        title
        description
        date
        client
        repository
        url
        tecnologiesCollection(limit: 8){
          items {
              title
              icon {
                url
              }
          }
        }
        galleryCollection(limit: 5) {
          items {
            url
          }
        }
      }
    }`;
  useEffect(() => {
    fetch(contentfulGraphqlEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${xyz}`,
      },
      body: JSON.stringify({ query }),
    })
      .then((response) => response.json())
      .then((data) => {
        setElement(data.data.project);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [query]);
  return (
    <section className="Portfolio-modal">
      <div className="Portfolio-modal-container">
        {element ? (
          <>
            <p className="Portfolio-modal-subtext">Proyecto</p>
            <h3 className="Portfolio-modal-title">{element.title}</h3>
            {element.galleryCollection && (
              <div className="Portfolio-modal-gallery">
                <div
                  className="Portfolio-modal-gallery-container"
                  id="portfolioGallery"
                  data-position="1"
                >
                  {element.galleryCollection.items.map((image, index) => (
                    <img
                      className="Portfolio-modal-gallery-image"
                      key={index}
                      src={image.url}
                      alt={`Imagen ${index + 1} del proyecto ${element.title}`}
                    />
                  ))}
                </div>
                <div className="Portfolio-modal-gallery-navigation">
                  <button
                    onClick={() =>
                      window["moveGallery"](false, "portfolioGallery")
                    }
                  >
                    <i className="fa-regular fa-circle-left"></i>
                  </button>
                  <button
                    onClick={() =>
                      window["moveGallery"](true, "portfolioGallery")
                    }
                  >
                    <i className="fa-regular fa-circle-right"></i>
                  </button>
                </div>
              </div>
            )}
            <p className="Portfolio-modal-date">{element.date}</p>
            {element.client && (
              <p className="Portfolio-modal-client">
                <span>Cliente:</span> {element.client}
              </p>
            )}
            {element.description &
            (
              <p className="Portfolio-modal-description">
                {element.description}
              </p>
            )}
            <h5 className="Portfolio-modal-subtitle">Tecnologías:</h5>
            <div className="Portfolio-modal-icons">
              {element.tecnologiesCollection.items.map((technology, index) => (
                <div className="Portfolio-modal-icons-element" key={index}>
                  <img
                    className="Portfolio-modal-icons-element-image"
                    src={technology.icon.url}
                    alt={technology.title}
                  />
                  <p className="Portfolio-modal-icons-element-title">
                    {technology.title}
                  </p>
                </div>
              ))}
            </div>
            <div className="Portfolio-modal-links">
              {element.url && (
                <a
                  className="Portfolio-modal-link"
                  href={element.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-solid fa-globe"></i>
                  <p className="Portfolio-modal-link-text">Ver proyecto</p>
                </a>
              )}
              {element.repository && (
                <a
                  className="Portfolio-modal-link"
                  href={element.repository}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-github"></i>
                  <p className="Portfolio-modal-link-text">Ver código</p>
                </a>
              )}
            </div>
          </>
        ) : (
          <h2>¡Ups! Hubo un problema</h2>
        )}
        <button className="Portfolio-modal-close" onClick={props.handleClick}>
          <i className="fa-regular fa-circle-xmark"></i>
        </button>
      </div>
    </section>
  );
}

export default Modal;
